<template>
<div class="evaluation mt-5 cart-Page cart-Page-adrreess">
    <div class="topboxorder">
        <div class="current-orders py-4">
            <div class="icons d-flex justify-content-center">
                <b-icon icon="circle-fill" class="orange circle-icon h4"></b-icon>
                <b-icon icon="dash" class="orange circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                <b-icon icon="dash" class="orange circle-icon"></b-icon>
                <b-icon icon="circle-fill" class="orange circle-icon h4"></b-icon>
                <b-icon icon="dash" class="orange circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
                <b-icon icon="dash" class="orange circle-icon"></b-icon>
                <b-icon icon="circle-fill" class="pink circle-icon h4"></b-icon>
                <b-icon icon="dash" class="pink circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="pink circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="pink circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="pink circle-icon"></b-icon>
                <b-icon icon="dash-lg" class="pink circle-icon"></b-icon>
                <b-icon icon="dash" class="pink circle-icon"></b-icon>
                <b-icon icon="circle-fill" class="pink circle-icon h4"></b-icon>
            </div>
            <div class="order-done d-flex justify-content-center pt-3">
                <span class="mx-5"> {{ $t("shopping cart") }} </span>
                <span class="mx-5"> {{ $t("shipping address") }} </span>
                <span class="mx-5"> {{ $t("payment") }} </span>
                <span class="mx-5">{{ $t("Submit a request") }} </span>
            </div>
        </div>
    </div>

    <div class="container items">
        <h5 class="d-inline-block ms-2 mb-3">{{ $t("payment") }}</h5>

        <div class="shopping-cart d-flex w-100">
            <div class="paying p-2">

                <div class="paying p-2" style="width: 100%;margin: 0 !important;">
                    <div class="address mb-3 d-flex justify-content-between">
                        <h6>{{ $t("shipping address") }}</h6>
                        <router-link to="/shippingAddress/addShippingAddress" class="border-0 addnewbtn">
                            <b-icon icon="plus" class="plus"></b-icon>
                            {{ $t("Add a new address") }}
                        </router-link>
                    </div>

                    <div class="row">
                        <div class="col-md-4 mb-3" v-for="item of list">
                            <div class="order-content" style="width: 100%">
                                <div class="date d-flex align-items-center mb-2">
                                    <img src="../../assets/images/map.svg" alt="" class="ml-5" />
                                    <div class="content w-86">
                                        <div class="content1 d-flex justify-content-between">
                                            <h6>{{ item.firstName }} {{ item.lastName }}</h6>

                                            <input type="radio" class="btn-check" name="addressId" :id="'address_' + item._id" :value="item._id" v-model="body.addressId" @change="handleClick" />
                                            <label class="btn btn-color" :for="'address_' + item._id" style="border-color: #f59f00 !important">
                                                <svg width="15" height="15" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="11.5" cy="11.5" r="11.5" fill="#F59F00" />
                                                </svg>
                                            </label>
                                        </div>
                                        <p>{{ item.phone }}</p>
                                        <div class="content1 d-flex justify-content-between">
                                            <p>{{ $textSlice(item.address, 30) }}</p>
                                            <img @click="$router.push('/editaddress/' + item._id)" src="../../assets/images/edit.svg" class="img-fluid rounded-start" alt="" style="cursor: pointer" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="payment-method mt-4">
                        <h5>
                            {{ $t("Payment method") }}
                        </h5>
                        <div class="d-flex justify-content-around flex-wrap mt-4 payment-method-phone">

                            <div class="cash">
                                <input type="radio" class="btn-check" name="paymentType" id="paymentType_online" value="online" v-model="body.paymentType" />
                                <label class="btn btn-color" for="paymentType_online" style="border-color: #f59f00 !important">
                                    <svg width="15" height="15" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="11.5" cy="11.5" r="11.5" fill="#F59F00" />
                                    </svg>
                                </label>
                                <img src="../../assets/images/cc.svg" class="ms-2" alt="" />

                                <span> {{ $t("Credit card") }}</span>
                            </div>

                            <div class="cash">
                                <input type="radio" class="btn-check" name="paymentType" id="paymentType_mada" value="mada" v-model="body.paymentType" />
                                <label class="btn btn-color" for="paymentType_mada" style="border-color: #f59f00 !important">
                                    <svg width="15" height="15" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="11.5" cy="11.5" r="11.5" fill="#F59F00" />
                                    </svg>
                                </label>
                                <img src="../../assets/payment/stc.png" class="ms-2" alt="" />

                                <span> {{ $t("Mada") }}</span>
                            </div>

                            <div class="cash">
                                <input type="radio" class="btn-check" name="paymentType" id="paymentType_stc" value="stc" v-model="body.paymentType" />
                                <label class="btn btn-color" for="paymentType_stc" style="border-color: #f59f00 !important">
                                    <svg width="15" height="15" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="11.5" cy="11.5" r="11.5" fill="#F59F00" />
                                    </svg>
                                </label>
                                <img src="../../assets/payment/stc1.png" class="ms-2" alt="" />

                                <span> STC </span>
                            </div>

                            <!-- <div class="cash">
                                <input type="radio" class="btn-check" name="paymentType" id="paymentType_tabby" value="tabby" v-model="body.paymentType" />
                                <label class="btn btn-color" for="paymentType_tabby" style="border-color: #f59f00 !important">
                                    <svg width="15" height="15" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="11.5" cy="11.5" r="11.5" fill="#F59F00" />
                                    </svg>
                                </label>
                                <img src="../../assets/payment/tab.png" class="ms-2" alt="" />

                                <span> TABBY </span>
                            </div> -->

                            <div id="cashPayment" class="cash">
                                <input type="radio" class="btn-check" name="paymentType" id="paymentType_cash" value="cash" @click="handlePaymentCash()" v-model="body.paymentType" />
                                <label class="btn btn-color" for="paymentType_cash" style="border-color: #f59f00 !important">
                                    <svg width="15" height="15" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="11.5" cy="11.5" r="11.5" fill="#F59F00" />
                                    </svg>
                                </label>
                                <img src="../../assets/images/cash.svg" class="ms-2" alt="" />
                                <span>
                                    {{ $t("Paiement when recieving") }}
                                </span>
                            </div>

                            <!-- <div class="cash">
                    <input
                      type="radio"
                      class="btn-check"
                      name="paymentType"
                      id="paymentType_paypal"
                      value="paypal"
                      v-model="body.paymentType"
                    />
                    <label
                      class="btn btn-color"
                      for="paymentType_paypal"
                      style="border-color: #f59f00 !important"
                    >
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 23 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="11.5" cy="11.5" r="11.5" fill="#F59F00" />
                      </svg>
                    </label>

                    <img src="../../assets/images/paypal.svg" class="ms-2" alt="" />
                    <span> {{ $t("paypal") }}</span>
                  </div>

                  <div class="cash">
                    <input
                      type="radio"
                      class="btn-check"
                      name="paymentType"
                      id="paymentType_apple"
                      value="apple"
                      v-model="body.paymentType"
                    />
                    <label
                      class="btn btn-color"
                      for="paymentType_apple"
                      style="border-color: #f59f00 !important"
                    >
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 23 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="11.5" cy="11.5" r="11.5" fill="#F59F00" />
                      </svg>
                    </label>
                    <img src="../../assets/images/apple.svg" class="ms-2" alt="" />

                    <span>{{ $t("apple pay") }}</span>
                  </div> -->
                        </div>
                    </div>
                    <div class="info mt-4">
                        <!-- <form action="" calss="" v-if="body.paymentType == 'cc'">
                  <h6 class="mb-4">
                    {{ $t("Credit card information") }}
                  </h6>
                  <div class="row">
                    <div class="col-md-6 mb-2">
                      <label for="exampleInputEmail1" class="form-label">
                        {{ $t("Name on the card") }}
                      </label>
                      <input
                        type="name"
                        v-model="body.cartInfo.cartName"
                        class="form-control"
                        id="exampleInputEmail1"
                      />
                    </div>
                    <div class="col-md-6 mb-2">
                      <label for="exampleInputEmail1" class="form-label">
                        {{ $t("cartNumber") }}
                      </label>
                      <input
                        type="name"
                        v-model="body.cartInfo.cartNumber"
                        class="form-control"
                        id="exampleInputEmail1"
                      />
                    </div>
                    <div class="col-md-6 mb-2">
                      <label for="exampleInputEmail1" class="form-label">
                        {{ $t("Cvv Number") }}
                      </label>
                      <input
                        type="name"
                        v-model="body.cartInfo.cvv"
                        class="form-control"
                        id="exampleInputEmail1"
                      />
                    </div>
                    <div class="col-md-6 mb-2">
                      <label for="" class="ms-2">{{ $t("Expiry date") }}</label>

                      <div>
                        <Dropdown
                          v-model="body.cartInfo.year"
                          :options="yearsList"
                          style=""
                          class="from-taab ml-2"
                          :filter="false"
                          :showClear="false"
                        />

                        <Dropdown
                          v-model="body.cartInfo.month"
                          :options="monthList"
                          style=""
                          optionLabel="name"
                          optionValue="value"
                          class="from-taab"
                          :filter="false"
                          :showClear="false"
                        />
                      </div>
                    </div>
                  </div>
                </form> -->
                        <!-- <div class="save-btn">
                  <button>حفظ</button>
                </div> -->
                    </div>
                    <div>
                        <div class="tamara-product-widget" id="tamara-product-widget" :data-price="Number(
                                cart.subTotal -
                                cart.discountValue +
                                calcVat(
                                    body.delivaryPrice + cart.subTotal - cart.discountValue
                                ) +
                                body.delivaryPrice
                            ).toFixed(2)" data-currency="SAR" data-country-code="SA" data-color-type="default" data-show-border="true" data-payment-type="installment" data-disable-installment="false" data-disable-paylater="true">

                        </div>
                        <br>
                    </div>
                    <div>
                        <!-- tttt -->
                        <div>

                            <Accordion>
                                <AccordionTab>

                                    <template #header>

                                        <h2 class="accordion-header" id="flush-headingTwo">
                                            <button class="accordion-button collapsed d-flex align-items-baseline gap-2" type="button" @click="payment = 'PAY_NOW'">

                                                <input type="radio" class="btn-check" name="payment" id="payment_PAY_NOW" value="PAY_NOW" v-model="payment" />
                                                <label class="btn btn-color" for="payment_PAY_NOW" style="border-color: #f59f00 !important">
                                                    <svg width="15" height="15" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="11.5" cy="11.5" r="11.5" fill="#F59F00" />
                                                    </svg>
                                                </label>

                                                <img class="tamara-inline-badge tamara-badge" src="../../assets/payment/tamara-logo-badge-ar.png" style="max-height: 25px; display: inline; vertical-align: middle; float: none; margin: 0 1rem; cursor: pointer; align-self:end;" alt="Tamara">

                                                {{ $t('tamara_one') }}

                                            </button>
                                        </h2>
                                    </template>

                                    <tamara-widget type="tamara-card-snippet"></tamara-widget>

                                </AccordionTab>

                                <AccordionTab v-for="item of instCountList">

                                    <template #header>

                                        <h2 class="accordion-header">
                                            <button class="accordion-button collapsed d-flex align-items-baseline gap-2" @click="payment = 'PAY_BY_INSTALMENTS_' + item.instalments; instCount = item.instalments" type="button">

                                                <input type="radio" class="btn-check" name="payment" :id="'PAY_BY_INSTALMENTS_' + item.instalments" :value="'PAY_BY_INSTALMENTS_' + item.instalments" v-model="payment" />
                                                <label class="btn btn-color" :for="'PAY_BY_INSTALMENTS_' + item.instalments" style="border-color: #f59f00 !important">
                                                    <svg width="15" height="15" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="11.5" cy="11.5" r="11.5" fill="#F59F00" />
                                                    </svg>
                                                </label>

                                                <img class="tamara-product-widget" style="max-height: 25px; display: inline; vertical-align: middle; float: none; margin: 0 1rem; cursor: pointer; align-self:end;" src="../../assets/payment/tamara-logo-badge-ar.png" alt="Tamara Checkout Icon">
                                                {{ $t('tamara_two_1') }}

                                                {{ item.instalments }}
                                                {{ $t('tamara_two_2') }} </button>
                                        </h2>
                                    </template>

                                    <iframe v-if="item.instalments > 0" :src="`https://cdn.tamara.co/widget/tamara-introduction.html?lang=${lang}&price=${Number(cart.subTotal - cart.discountValue + calcVat(body.delivaryPrice + cart.subTotal - cart.discountValue) + body.delivaryPrice)}&currency=SAR&colorType=default&countryCode=SA&numberOfInstallments=${item.instalments}&widgetType=installment-plan`" frameborder="0" style="width: 100%;height: 208px;"></iframe>

                                </AccordionTab>
                            </Accordion>

                        </div>
                    </div>
                </div>
            </div>

            <div class="discount-box">
                <div class="discount">
                    <div class="gifts">
                        <p>{{ $t("Discount coupons and gift cards") }}</p>
                        <div class="btns d-flex justify-content-between">
                            <input type="text" class="sign1" :value="cart.discountId.name" :placeholder="$t('Enter the coupon or card code')" v-if="cart.discountId" disabled />
                            <input v-else type="text" class="sign1" v-model="discountCode" :placeholder="$t('Enter the coupon or card code')" @keyup.enter="checkDescount()" />
                            <button class="sign2" @click="deleteDiscount()" v-if="cart.discountId">
                                {{ $t("cncel") }}
                            </button>
                            <button class="sign2" @click="checkDescount()" v-else>
                                {{ $t("use") }}
                            </button>
                        </div>
                    </div>

                    <div class="orders-summary">
                        <h5>
                            {{ $t("Order summary") }}
                        </h5>
                        <div class="date d-flex justify-content-between mb-2">
                            {{ $t("Subtotal") }}
                            <span class="price">{{ cart.subTotal }} {{ $t("SAR") }}</span>
                        </div>
                        <div class="date d-flex justify-content-between mb-2" v-if="cart.discountValue > 0">
                            <p>{{ $t("Discount") }}</p>
                            <span class="price" id="discountValue">-{{ (cart.discountValue).toFixed(2) }} {{ $t("SAR")
                                        }}</span>
                        </div>
                        <!-- <div
                    class="date d-flex justify-content-between mb-2"
                     v-if="cart.discountValue > 0"
                  >
                    <p>{{ $t("priceBeforeDiscount") }}</p>
                    <span class="price"
                      >{{ cart.total-cart.discountValue }} {{ $t("SAR") }}</span
                    >
                  </div> -->
                        <!-- <div
                    class="date d-flex justify-content-between mb-2"
                     v-if="cart.discountValue == 0"
                  >
                    <p>{{ $t("priceBeforeDiscount") }}</p>
                    <span class="price"
                      >0 {{ $t("SAR") }}</span
                    >
                  </div> -->
                        <div class="date d-flex justify-content-between mb-2" v-if="body.addressId">
                            <p>{{ $t("Delivery costs") }}</p>
                            <span class="price" v-if="body.delivaryPrice > 0" id="delivaryPrice">{{ body.delivaryPrice }} {{ $t("SAR")
                                        }}</span>

                            <span class="price" v-if="body.delivaryPrice == 0" id="delivaryPrice"> {{ $t("freeShipping")
                                        }}</span>
                        </div>

                        <div class="date d-flex justify-content-between mb-2">
                            <p>{{ $t("tax") }}</p>
                            <p style="display:none" id="taxVal">{{
                                        calcVat(
                                            body.delivaryPrice + cart.subTotal - cart.discountValue
                                        ).toFixed(2)
                                    }}</p>

                            <p class="price">{{
                                        calcVat(
                                            body.delivaryPrice + cart.subTotal - cart.discountValue
                                        ).toFixed(2)
                                    }}
                                {{ $t("SAR") }}</p>
                        </div>
                        <!-- <div class="date d-flex justify-content-between mb-2">
                    <p>{{ $t("net") }}</p>
                    <span class="price"
                      >{{ ((cart.total-cart.discountValue)+
                      (calcVat((body.delivaryPrice + cart.total)-cart.discountValue))).toFixed(2) }}
                      {{ $t("SAR") }}</span
                    >
                  </div> -->

                        <div class="date d-flex justify-content-between mb-2">
                            <p>{{ $t("Total") }}</p>
                            <p style="display:none" id="total">
                                {{
                                            (
                                                cart.subTotal -
                                                cart.discountValue +
                                                calcVat(
                                                    body.delivaryPrice + cart.subTotal - cart.discountValue
                                                ) +
                                                body.delivaryPrice
                                            ).toFixed(2)
                                        }}

                            </p>
                            <span class="price">{{
                                        (
                                            cart.subTotal -
                                            cart.discountValue +
                                            calcVat(
                                                body.delivaryPrice + cart.subTotal - cart.discountValue
                                            ) +
                                            body.delivaryPrice
                                        ).toFixed(2)
                                    }}
                                {{ $t("SAR") }}</span>
                        </div>
                    </div>

                    <div class="delivery-costs mt-4">
                        <h6 v-if="body.addressId == null">
                            {{ $t("Delivery costs") }}
                        </h6>
                        <p v-if="body.addressId == null">
                            {{
                                        $t("It will be calculated after adding the delivery address")
                                    }}
                        </p>
                        <!-- <p v-if="body.addressId">{{ body.delivaryPrice }}</p> -->
                        <!-- <div class="plus-btn2"> -->

                        <div class="mb-3">
                            <textarea v-model="body.notes" name="notes" class="form-control" id="" rows="8" :placeholder="$t('write notes')"></textarea>
                        </div>
                        <div class="text-center">
                            <button :class="canAddVar ? 'px-0' : 'border-0 btn-done-cart'" @click="done()">
                                {{ $t("Complete your purchase") }}
                            </button>
                        </div>
                        <!-- </div> -->

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
export default {
    data() {
        const month = this.$moment.monthsShort();
        const monthList = [];
        let o = 1;
        for (const item of month) {
            monthList.push({
                name: item,
                value: "0" + o
            });
            o++;
        }
        const yearsList = [];
        const srartYear = 2023;
        for (let i = srartYear; i <= srartYear + 20; i++) {
            yearsList.push(i);
        }
        const lang = localStorage.lang || "en";
        return {
            lang,
            payment: null,
            instCount: 0,
            instCountList: [],
            canAddVar: false,
            cart: {
                subTotal: 0,
                total: 0,
                cartItemsList: []
            },
            body: {
                addressId: null,
                paymentType: null,
                delivaryPrice: 0,
                cartInfo: {
                    cartName: "Jane Jones 1",
                    cartNumber: "5123450000000008",
                    cvv: "100",
                    month: "01",
                    year: 2039
                },
                done: true
            },
            discountCode: null,
            list: [],
            yearsList,
            monthList
        };
    },
    methods: {
        calcVat(num) {
            return num * 0.15;
             this.body.total = Number(document.getElementById('total').innerHTML)
        },
        handleClick(myRadio) {
            let totalBeforeShipping = Number(
                this.cart.subTotal -
                this.cart.discountValue

            );

            // this.body.delivaryPrice =  1

            this.body.delivaryPrice = Number(Number(totalBeforeShipping *.1).toFixed(2))
        },
        handlePaymentCash() {
            let totalBeforeShipping = Number(
                this.cart.subTotal -
                this.cart.discountValue

            );

            // this.body.delivaryPrice =  1

            this.body.delivaryPrice = Number(Number(totalBeforeShipping *.1).toFixed(2))
            // this.$http
            //     .post("address/getUserAddresses?limit=1000000", {})
            //     .then((res) => {
            //         let findObjAddress = res.data.docs.find(
            //             (li) => li._id == this.body.addressId
            //         );

            //         if (findObjAddress) {
            //             this.body.delivaryPrice = findObjAddress.cityId.shippingValue;
            //         }
            //     });
        },

        done() {

            if (!this.body.paymentType || this.body.paymentType == null) {
                this.$toast.add({
                    severity: "error",
                    summary: this.$t("Error"),
                    detail: this.$t("Must be Select paymentType"),
                    life: 3000
                });
                return;
            }
            let discountValue;
            if (this.body.delivaryPrice != null) {
                // discountValue = this.cart.discountValue ? this.cart.discountValue : 0;
                this.body.taxValue = Number(document.getElementById('taxVal').innerHTML)

                this.body.total = Number(document.getElementById('total').innerHTML)

            }

            if (this.body.addressId == null) {
                this.$toast.add({
                    severity: "error",
                    summary: this.$t("Error"),
                    detail: this.$t("Must be Select Address"),
                    life: 3000
                });
                return;
            }
            // if (this.body.paymentType == "cc") {
            //   if (
            //     this.body.cartInfo.cartName == null ||
            //     this.body.cartInfo.cartNumber == null ||
            //     this.body.cartInfo.cvv == null
            //   ) {
            //     this.$toast.add({
            //       severity: "error",
            //       summary: this.$t("Error"),
            //       detail: this.$t("Must be Add Cart Name"),
            //       life: 3000,
            //     });
            //     return;
            //   }
            // }

            this.$http.post("cart/doneCart", this.body).then(
                (res) => {
                    // this.$eventHub.$emit("updateCart", {});
                    // this.$swal({
                    //   title: this.$t("Successfully Done"),
                    //   icon: "success",
                    //   timer: 3000,
                    //   showConfirmButton: false,
                    // });
                    if (
                        this.body.paymentType == "online" ||
                        this.body.paymentType == "stc" ||
                        this.body.paymentType == "tabby" ||
                        this.body.paymentType == "mada"
                    ) {
                        this.$router.push(
                            "payCart/" +
                            res.data.data.id +
                            "/" +
                            this.cart._id +
                            "/" +
                            this.body.paymentType
                        );
                    } else if (this.body.paymentType == "tamara") {

                        this.$http.post("cart/createCheckout", {
                            cartsId: this.cart._id,

                            "locale": this.lang == 'ar' ? "ar_SA" : 'en_US', // en_US or ar_SA
                            "payment_type": this.payment == 'PAY_NOW' ? 'PAY_NOW' : "PAY_BY_INSTALMENTS", // PAY_BY_INSTALMENTS || PAY_NOW
                            "instalments": this.instCount, // if use PAY_BY_INSTALMENTS,

                        }).then(
                            (res) => {
                                if (res.data && res.data.data && res.data.data.checkoutUrl) {

                                    window.location.href = res.data.data.checkoutUrl;
                                }
                            },
                            (err) => {
                                this.$toast.add({
                                    severity: "error",
                                    summary: this.$t("Error"),
                                    detail: err.response.data.message,
                                    life: 3000
                                });
                            }
                        );
                    } else {
                        this.$router.push("doneCart/" + this.cart._id);
                    }
                },
                (err) => {
                    this.$toast.add({
                        severity: "error",
                        summary: this.$t("Error"),
                        detail: err.response.data.message,
                        life: 3000
                    });
                }
            );
        },
        checkDescount() {
            this.$http
                .post("discounts/checkdiscountCode", {
                    discountCode: this.discountCode
                })
                .then(
                    (res) => {
                        this.getCarts();
                    },
                    (err) => {
                        this.$toast.add({
                            severity: "error",
                            summary: this.$t("Error"),
                            detail: err.response.data.message,
                            life: 3000
                        });
                    }
                );
        },
        deleteDiscount() {
            this.$http.post("cart/deleteDiscount", {}).then((res) => {
                this.getCarts();
            });
        },
        getCarts() {
            // location.reload();
            this.$http.get("cart/").then(
                (res) => {
                    
                    
                    if (res.data.returnStatus) {

                        this.cart = res.data.data;
                        console.log(this.cart);
                        
                        let totalBeforeShipping = Number(
                            this.cart.subTotal -
                            this.cart.discountValue

                        );
                        if (this.settingsList.freeShippingEndDate != null) {

                            let today = this.$moment(new Date()).format("MM/DD/YYYY");
                            let startDay = this.$moment(new Date(this.settingsList.freeShippingStartDate)).format("MM/DD/YYYY");
                            let endDay = this.$moment(new Date(this.settingsList.freeShippingEndDate)).format("MM/DD/YYYY");
                            var new_date = this.$moment(new Date(this.settingsList.freeShippingEndDate)).add(1, 'days').format("MM/DD/YYYY");
                            if (this.$moment(today).isBetween(startDay, new_date)) {
                                this.body.delivaryPrice = 0
                            } else {

                                // this.body.delivaryPrice =  1
                                this.body.delivaryPrice = Number(Number(totalBeforeShipping *.1).toFixed(2))

                            }
                        } 
                       
                        
                        else if (this.settingsList.freeShippingValue != null && totalBeforeShipping > this.settingsList.freeShippingValue) {
                            this.body.delivaryPrice = 0
                        } else {

                            this.body.delivaryPrice = Number(Number(totalBeforeShipping *.1).toFixed(2))
                        }

                        // if (this.cart.subTotal != this.cart.total) {
                        //   location.reload();

                        // }

                        // تأكد من أن التهيئة والاستدعاء يتمان هنا
                        this.initializeTamaraWidget();

                    } else {
                        this.$router.push("/");
                    }
                },
                (err) => {
                    this.$router.push("/");
                    this.$toast.add({
                        severity: "error",
                        summary: this.$t("Error"),
                        detail: err.response.data.message,
                        life: 3000
                    });
                }
            );
        },

        canAdd() {
            // location.reload();
            if (this.body.addressId != null) {
                // if (this.body.paymentType == "cash") {
                this.canAddVar = true;
                // } else if (
                //   this.body.paymentType == "cc" &&
                //   this.body.cartInfo.cartName &&
                //   this.body.cartInfo.cartNumber &&
                //   this.body.cartInfo.cvv &&
                //   this.body.cartInfo.month &&
                //   this.body.cartInfo.year
                // ) {
                //   this.canAddVar = true;
                // } else if (this.body.paymentType == "paypal") {
                //   this.canAddVar = true;
                // } else if (this.body.paymentType == "apple") {
                //   this.canAddVar = true;
                // } else {
                //   this.canAddVar = false;
                // }
            } else {
                this.canAddVar = false;
            }
        },
        initializeTamaraWidget() {

            const total = Number(
                this.cart.subTotal -
                this.cart.discountValue +
                this.calcVat(
                    this.body.delivaryPrice + this.cart.subTotal - this.cart.discountValue
                ) +
                this.body.delivaryPrice
            );
            
            if (total >= 1500) {
                document.getElementById("cashPayment").style.display="none"
            }

            if (total > 0) {
                this.$http.get("cart/getPaymentTypes/" + total).then((res) => {
                    this.instCountList = res.data.data[0].supportedInstalments;
                });
            }
            setTimeout(() => {
                window.TamaraProductWidget.init({
                    lang: this.lang,
                    country: 'SA',
                    publicKey: 'b060df37-f93e-46f2-88b0-2162797facdc',
                });
                const x = window.TamaraProductWidget.render();
            }, 1000);

        },
    },

    mounted() {

        setTimeout(() => {
            this.getCarts();
        }, 1000);
        this.$http
            .get("settings/getOneSetting", {})
            .then((res) => {
                this.settingsList = res.data;

            });
        this.$http
            .post("address/getUserAddresses?limit=1000000", {})
            .then((res) => {
                this.list = res.data.docs;
                if (this.list.length > 0) {
                    this.body.addressId = this.list[0]._id;

                    // if (document.getElementById("paymentType_cash") paymentType_cash) {

                    // }

                }
            });
    },
    watch: {
        "body.addressId"() {
            this.canAdd();
            this.initializeTamaraWidget();
        },
        "body.paymentType"() {
            this.payment = null;
            this.canAdd();
            this.initializeTamaraWidget();
        },
        payment(val) {
            if (val) {
                this.body.paymentType = 'tamara';
            }

        },
        // "body.cartInfo.cartName"() {
        //   this.canAdd();
        // },
        // "body.cartInfo.cartNumber"() {
        //   this.canAdd();
        // },
        // "body.cartInfo.cvv"() {
        //   this.canAdd();
        // },
        // "body.cartInfo.month"() {
        //   this.canAdd();
        // },
        // "body.cartInfo.year"() {
        //   this.canAdd();
        // },
    }
};
</script>

<style></style>
